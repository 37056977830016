export enum FilterTypeFields {
  View = 'view',
  FromDate = 'fromTime',
  ToDate = 'toTime',
  FullCalendarFromDate = 'fromFull',
  FullCalendarToDate = 'toFull',
  Period = 'period',
  Back = 'back',
  DarkMode = 'darkMode'
}

export type FilterTypes =
  | FilterTypeFields.View
  | FilterTypeFields.FromDate
  | FilterTypeFields.ToDate
  | FilterTypeFields.Period
  | FilterTypeFields.DarkMode
  | FilterTypeFields.Back;
