import { Platform } from '@angular/cdk/platform';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { FiltersStoreService } from '@core/filters-store.service';
import { AuthService } from '@features/auth/auth.service';
import { FilterTypeFields } from '@features/mobile-scheduler/filterTypes';
import { MobileSchedulerService } from '@features/mobile-scheduler/mobile-scheduler.service';
import { MobileSchedulerTabs } from '@features/mobile-scheduler/tabs.items';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@services/common/storage.service';
import { PeriodTypes, PeriodTypesListOptions } from '@shared/components/scheduler-period-picker/filterTypes';
import { FilterStorageModel } from '@shared/models/filtersStore.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { TimeService } from '@services/common/time.service';
import { take, takeUntil, filter } from 'rxjs/operators';
import { MobileSchedulerTypesEnum } from './mobile-scheduler-types.enum';

@Component({
  selector: 'mobile-scheduler',
  templateUrl: './mobile-scheduler.component.html',
  styleUrls: ['./mobile-scheduler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileSchedulerComponent implements OnInit, OnDestroy {
  filters: FilterStorageModel;
  tabs: any = MobileSchedulerTabs;
  filterTypesEnum = FilterTypeFields;
  periodOptions = PeriodTypesListOptions;
  MobileSchedulerTypesEnum = MobileSchedulerTypesEnum;
  fromDate: string;
  toDate: string;
  period: string;
  isAdmin: boolean;
  isManager: boolean;
  isWorker: boolean;
  timesheetDays: any;
  periodTypes = PeriodTypes;

  isAuthorized = new BehaviorSubject(false);

  private _destroyed$ = new Subject();

  setPeriodFilters(values, filterTypes, filters: FilterStorageModel): void {
    filters.set(filterTypes.Period, values.period || values.value);
    filters.set(filterTypes.FromDate, values.fromTime || values.fromDate);
    filters.set(filterTypes.ToDate, values.toTime || values.toDate);
  }

  setPeriodFullFilters(values, filterTypes, filters: FilterStorageModel): void {
    if (values?.fromFull && values?.toFull) {
      filters.set(filterTypes.FullCalendarFromDate, values?.fromFull || values.fromDate);
      filters.set(filterTypes.FullCalendarToDate, values?.toFull || values.toDate);
    }
  }

  isPeriodFilterObj(obj): boolean {
    return (
      obj.hasOwnProperty(this.filterTypesEnum.Period) &&
      obj.hasOwnProperty(this.filterTypesEnum.FromDate) &&
      obj.hasOwnProperty(this.filterTypesEnum.ToDate) &&
      obj.hasOwnProperty(this.filterTypesEnum.FullCalendarFromDate) &&
      obj.hasOwnProperty(this.filterTypesEnum.FullCalendarToDate)
    );
  }

  changeActiveView({ tab }) {
    const value = tab.value;
    this.selectedTab(value);
    this.filters.set(this.filterTypesEnum.View, value).setToUrl(false);
    this.filters.changed.next(this.filters.getAll());
  }

  updateSelectedItems(data): void {
    const items = data.items || data.data.model;
    const type = data.type || items.type;

    switch (type) {
      case this.filterTypesEnum.Period:
        this.period = items.period || items.value;
        this.fromDate = items.fromDate;
        this.toDate = items.toDate;

        const filterValues = {
          period: this.period,
          fromDate: this.fromDate,
          toDate: this.toDate
        };

        this.setPeriodFilters(filterValues, this.filterTypesEnum, this.filters);
        break;
      case this.filterTypesEnum.ToDate:
        this.toDate = items.toTime || items.toDate;
        this.filters.set(this.filterTypesEnum.ToDate, this.toDate);
        break;
      case this.filterTypesEnum.FromDate:
        this.fromDate = items.fromTime || items.fromDate;
        this.filters.set(this.filterTypesEnum.FromDate, this.fromDate);
        break;
    }

    this.filters.setToUrl(false).then(() => {
      const routeParams = this._route.snapshot.queryParams;
      this.initFilters(routeParams);
    });
  }

  updateFullCalendarSelectedItems(data): void {
    const items = data.items || data.data.model;
    const type = data.type || items.type;

    switch (type) {
      case this.filterTypesEnum.Period:
        this.period = items.period || items.value;
        this.fromDate = items.fromDate;
        this.toDate = items.toDate;

        const filterValues = {
          fromFull: this.fromDate,
          toFull: this.toDate
        };

        this.setPeriodFullFilters(filterValues, this.filterTypesEnum, this.filters);
        break;
      case this.filterTypesEnum.FullCalendarToDate:
        this.toDate = items.toTime || items.toDate;
        this.filters.set(this.filterTypesEnum.FullCalendarToDate, this.toDate);
        break;
      case this.filterTypesEnum.FullCalendarFromDate:
        this.fromDate = items.fromTime || items.fromDate;
        this.filters.set(this.filterTypesEnum.FullCalendarFromDate, this.fromDate);
        break;
    }

    this.filters.setToUrl(false).then(() => {
      const routeParams = this._route.snapshot.queryParams;
      this.initFilters(routeParams);
    });
  }

  private initFilters(routeParams) {
    if (this.isPeriodFilterObj(routeParams)) {
      this.setPeriodFilters(routeParams, this.filterTypesEnum, this.filters);
      this.setPeriodFullFilters(routeParams, this.filterTypesEnum, this.filters);
    } else {
      const periodObject = this.periodOptions.find((option) => {
        return option.value === PeriodTypes.Week;
      });

      periodObject['fromFull'] = periodObject.fromDate;
      periodObject['toFull'] = periodObject.toDate;

      this.setPeriodFilters(periodObject, this.filterTypesEnum, this.filters);
      this.setPeriodFullFilters(periodObject, this.filterTypesEnum, this.filters);
    }

    if (this.isWorker) {
      this.filters.set(this.filterTypesEnum.View, MobileSchedulerTypesEnum.MySchedule);
    }

    if (routeParams.hasOwnProperty(this.filterTypesEnum.View)) {
      this.filters.set(this.filterTypesEnum.View, routeParams.view);
    } else {
      this.filters.set(this.filterTypesEnum.View, MobileSchedulerTypesEnum.MySchedule);
    }

    if (routeParams.hasOwnProperty(this.filterTypesEnum.Back)) {
      this.filters.set(this.filterTypesEnum.Back, routeParams.back);
    } else {
      this.filters.set(this.filterTypesEnum.Back, MobileSchedulerTypesEnum.Show);
    }

    if (routeParams.hasOwnProperty(this.filterTypesEnum.DarkMode)) {
      this.filters.set(this.filterTypesEnum.DarkMode, routeParams.darkMode);
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }

    this.filters.setToUrl(false).then(() => {
      this.setSelectedTab();
    });

    this.filters.changed.next(this.filters.getAll());
  }

  private selectedTab(mobileSchedulerType: MobileSchedulerTypesEnum): void {
    this.filters.set(FilterTypeFields.View, mobileSchedulerType);
  }

  private setSelectedTab(): void {
    const routeParams = this._route.snapshot.queryParams;
    let tab = routeParams['view'];

    if (this.isWorker) {
      tab = MobileSchedulerTypesEnum.MySchedule;
    }

    if (!tab) {
      tab = this.filters.get('view');
    }

    if (tab === MobileSchedulerTypesEnum.MySchedule && tab) {
      this.tabs.selected = this.tabs.items[0];
    } else if (tab === MobileSchedulerTypesEnum.FullSchedule && tab) {
      this.tabs.selected = this.tabs.items[1];
    }
  }

  private initUserRole() {
    this.isAdmin = this._auth.isAdmin();
    this.isManager = this._auth.isManager();
    this.isWorker = this._auth.isWorker();
  }

  backToNative() {
    if (this._platform.IOS) {
      // @ts-ignore
      window?.webkit?.messageHandlers?.jsBackHandler.postMessage('back from ios');
    } else if (this._platform.ANDROID) {
      // @ts-ignore
      window?.AndroidJsBridge?.goBack();
    }
  }

  authUser() {
    const accessToken = this._localStorage.getItem('access_token');
    const userId = this._localStorage.getItem('userId');
    const language = this._localStorage.getItem('mobileLanguage');

    this._translateService.use(language || 'en');

    if (accessToken && userId) {
      this._localStorage.setItem('token', accessToken);
      this._localStorage.setItem('user', {
        id: userId
      });

      return this._auth.initProfile();
    }

    return of(null);
  }

  updateViewportMeta(): void {
    this._meta.updateTag({
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
    });
  }

  loginMethod(): void {
    this.authUser()
      .pipe(takeUntil(this._destroyed$))
      .subscribe((data) => {
        if (data !== null && data.hasOwnProperty('first_name')) {
          this.initUserRole();
          this.isAuthorized.next(true);
        }
      });
  }

  initSegmentEventSubscription() {
    this.isAuthorized
      .pipe(
        filter((val) => !!val),
        take(1)
      )
      .subscribe(() => {
        if (this._platform.IOS) {
          // @ts-ignore
          window?.webkit?.messageHandlers?.jsOpenScheduler.postMessage(true);
        } else if (this._platform.ANDROID) {
          // @ts-ignore
          window?.AndroidJsBridge?.openScheduler();
        }
      });
  }

  requestToLogin() {
    // @ts-ignore
    window.requestToLogin = () => {
      window.document.getElementById('loginButton').click();
    };
  }

  private initTimeSheetDays(): void {
    const periodDates = this._timeService.getPeriodDates(
      this.filters.get(this.filterTypesEnum.FromDate),
      this.filters.get(this.filterTypesEnum.ToDate)
    );
    this.timesheetDays = periodDates.days;
  }

  private filterSubscription() {
    this.filters.changed
      .pipe(
        takeUntil(this._destroyed$),
        filter((params) => !!params)
      )
      .subscribe(() => this.initTimeSheetDays());
  }

  ngOnInit(): void {
    this._mobileSchedulerService.setIsMobile(true);
    this.updateViewportMeta();

    this.loginMethod();

    this.filters.setToUrl().then(() => {
      const routeParams = this._route.snapshot.queryParams;
      this.filterSubscription();

      this.initFilters(routeParams);
      this.setSelectedTab();
    });
    this.initSegmentEventSubscription();
  }

  ngOnDestroy(): void {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }

  constructor(
    private _filtersStore: FiltersStoreService,
    private _route: ActivatedRoute,
    private _auth: AuthService,
    private _localStorage: StorageService,
    private _mobileSchedulerService: MobileSchedulerService,
    private _translateService: TranslateService,
    public _platform: Platform,
    public _meta: Meta,
    private _timeService: TimeService
  ) {
    this.requestToLogin();
    this.filters = this._filtersStore.filters.mobileScheduler;
  }
}
