import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { MobileSchedulerTypesEnum } from '@features/mobile-scheduler/mobile-scheduler-types.enum';

export const MobileSchedulerTabs: any = {
  class: '',
  show: true,
  items: [
    {
      title: marker('My Schedule'),
      translationKey: '',
      class: 'tabs-item',
      show: true,
      value: MobileSchedulerTypesEnum.MySchedule
    },
    {
      title: marker('Full Schedule'),
      translationKey: '',
      class: 'tabs-item',
      show: true,
      value: MobileSchedulerTypesEnum.FullSchedule
    }
  ]
};
