import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateUiModule } from '@core/translation/translate-ui.module';
import { MobileMySchedulerModule } from '@features/mobile-scheduler/mobile-my-scheduler/mobile-my-scheduler.module';
import { MobileSchedulerRoutingModule } from '@features/mobile-scheduler/mobile-scheduler-routing.module';
import { AbsenceDetailTooltipModule } from '@features/scheduler/work-scheduler/absence-tooltip/absence-detail-tooltip.module';
import { LetModule } from '@rx-angular/template/let';
import { PushModule } from '@rx-angular/template/push';
import { LogoModule } from '@shared/components/logo/logo.module';
import { SchedulerPeriodPickerModule } from '@shared/components/scheduler-period-picker/scheduler-period-picker.module';
import { ActionsTileModule } from '@ui-kit/actions-tile/actions-tile.module';
import { ButtonsModule } from '@ui-kit/buttons/buttons.module';
import { LoaderModule } from '@ui-kit/loader/loader.module';
import { CommonPipesModule } from '@ui-kit/pipes/common-pipes.module';
import { TabsTileModule } from '@ui-kit/tabs-tile/tabs-tile.module';
import { LongPressDirective } from './long-press.directive';
import { MobileFullScheduleComponent } from './mobile-full-schedule/mobile-full-schedule.component';
import { MobileSchedulerComponent } from './mobile-scheduler.component';
import { SumHoursModule } from '@shared/components/sum-hours/sum-hours.module';

@NgModule({
  declarations: [MobileSchedulerComponent, MobileFullScheduleComponent, LongPressDirective],
  imports: [
    CommonModule,
    SchedulerPeriodPickerModule,
    TabsTileModule,
    TranslateUiModule,
    ActionsTileModule,
    LoaderModule,
    LogoModule,
    AbsenceDetailTooltipModule,
    DragDropModule,
    ButtonsModule,
    MatExpansionModule,
    MobileSchedulerRoutingModule,
    SchedulerPeriodPickerModule,
    CommonPipesModule,
    MobileMySchedulerModule,
    SumHoursModule,
    PushModule,
    LetModule
  ]
})
export class MobileSchedulerModule {}
