export enum MobileSchedulerTypesEnum {
  MySchedule = 'mySchedule',
  FullSchedule = 'fullSchedule',
  Show = 'show',
  Hide = 'hide'
}

export type MobileSchedulerTypes =
  | MobileSchedulerTypesEnum.MySchedule
  | MobileSchedulerTypesEnum.FullSchedule
  | MobileSchedulerTypesEnum.Show
  | MobileSchedulerTypesEnum.Hide;
