import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Platform } from '@angular/cdk/platform';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ActionsService } from '@core/actions-modals/actions.service';
import { ApiService } from '@core/api.service';
import { FiltersStoreService } from '@core/filters-store.service';
import { AbsenceActionsService } from '@features/absence/absence-actions.service';
import { AuthService } from '@features/auth/auth.service';
import { FilterTypeFields } from '@features/scheduler/scheduler-container/filters/filterTypes';
import { SchedulerService } from '@features/scheduler/scheduler.service';
import { AbsenceDetailTooltipComponent } from '@features/scheduler/work-scheduler/absence-tooltip/absence-detail-tooltip.component';
import { SortTooltipComponent } from '@features/scheduler/work-scheduler/sort-tooltip/sort-tooltip.component';
import { WorkDetailTooltipComponent } from '@features/scheduler/work-scheduler/work-detail-tooltip/work-detail-tooltip.component';
import { TranslateService } from '@ngx-translate/core';
import { EntityEditService } from '@services/common/entity-edit.service';
import { HolidaysService } from '@services/common/holidays.service';
import { TimeService } from '@services/common/time.service';
import { FilterStorageModel } from '@shared/models/filtersStore.model';
import { IActionModel } from '@ui-kit/actions-tile/actions-tile.component';
import { ToastStatusEnum } from '@ui-kit/app-toast/toast-status.enum';
import { ToasterService } from '@ui-kit/app-toast/toaster.service';
import { handleError } from '@ui-kit/utility/handleHttpErrorResponse';
import { CrudService } from 'collection';
import { Collection, CollectionModel, DeleteEntityModel, Item } from 'collection';
import { format } from 'date-fns';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { MobileSchedulerTypesEnum } from '../mobile-scheduler-types.enum';

import { legacyParse, convertTokens } from '@date-fns/upgrade/v2';

@Component({
  selector: 'mobile-full-schedule',
  templateUrl: './mobile-full-schedule.component.html',
  styleUrls: ['./mobile-full-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileFullScheduleComponent implements OnInit, OnDestroy {
  progressCellWidth: number = 50; // 50 is min-width
  filters: FilterStorageModel;
  worksCollection: CollectionModel;
  workersCollection: CollectionModel;
  deleteManyItems: DeleteEntityModel;
  timesheetDays: Array<any> = [];
  groupsTimesheetDays;
  weekNumber;
  timeSheetData = {};
  filterTypesEnum = FilterTypeFields;
  selectedWorkCollection: CollectionModel;
  holidaysObj: { [key: string]: boolean } = {};
  today = format(legacyParse(new Date()), convertTokens('YYYY-MM-DD'));
  showUnscheduledSection = false;
  MobileSchedulerTypesEnum = MobileSchedulerTypesEnum;
  selectedYear = null;
  cdkWorkersList: any = [];
  selectedWorksByDate = {};
  selectedWorksByWorker = {};
  selectedWorks = new Set<number>();
  selectedUnpublishedAssignedWorks = new Set<number>();

  worksSelected = new Subject();

  $isSelectMode = new BehaviorSubject(false);
  isSelectMode: boolean = false;
  isStartDrag: boolean = false;

  actions: Array<IActionModel> = [
    {
      name: 'Delete',
      icon: 'icon-Delete_ico',
      actionClass: 'delete-action',
      show: true,
      click: () => {
        const selectedArray = Array.from(this.selectedWorks);
        this.deleteMany(selectedArray);
      }
    },
    {
      name: 'Publish',
      icon: 'icon-Save_ico',
      show: true,
      disabled: false,
      click: () => {
        this.publish();
      }
    }
  ];

  @ViewChild('plannerScroll') scrollWrapper: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.calculateProgressCellWidth();
    this._cdRef.markForCheck();
  }

  private _destroyed$ = new Subject();

  get selectedWorksSize() {
    return this.selectedWorks?.size;
  }

  get selectedUnpublishedAssignedWorksSize() {
    return this.selectedUnpublishedAssignedWorks?.size;
  }

  deleteMany(ids: Array<number>): void {
    const titlePlural = marker('Are you sure you want to delete these works?');
    const title = marker('Are you sure you want to delete this work?');
    const config = {
      title: ids.length > 1 ? titlePlural : title,
      description: 'You are not able to undo this action',
      confirmButtonText: 'Yes, I confirm'
    };

    this._actionsService
      .confirm(config)
      .pipe(take(1))
      .subscribe(({ confirmed, modal }) => {
        if (confirmed) {
          this.deleteManyItems
            .delete({ params: { 'ids[]': ids } })
            .pipe(
              map(() => {
                this.unSelectAllWorks();
                this.worksCollection.search();
                modal.close();
                this._toastService.show({
                  type: 'success',
                  message: marker('Changes are successfully saved')
                });
              }),
              catchError(({ error }): any => {
                modal.close();
                handleError(error, this._toastService);
              })
            )
            .subscribe();
        }
      });
  }

  private publishMessageForMobiles(counter: number) {
    if (this._platform.IOS) {
      // @ts-ignore
      window?.webkit?.messageHandlers?.jsWorkPublished.postMessage({
        title: 'publishWork',
        body: { counter }
      });
    } else if (this._platform.ANDROID) {
      // @ts-ignore
      window?.AndroidJsBridge?.workPublished(counter);
    }
  }

  publish() {
    const title = this._translate.instant('Publish work schedule?');
    const titlePlural = this._translate.instant('Publish {{ count }} Work Schedules?', {
      count: this.selectedUnpublishedAssignedWorksSize
    });

    const config = {
      title: this.selectedUnpublishedAssignedWorksSize > 1 ? titlePlural : title,
      description: marker(
        "Work schedules aren't visible to employees until they are published. When published we will send the employees a notification of the upcoming work"
      ),
      confirmButtonText: marker('Yes, Publish'),
      iconUrl: 'assets/img/scheduler.svg',
      size: 'sm'
    };

    this._actionsService
      .confirm(config)
      .pipe(
        take(1),
        filter(({ confirmed }) => !!confirmed),
        switchMap(({ modal }) => {
          const data = { ids: Array.from(this.selectedUnpublishedAssignedWorks) };
          return this._schedulerService.publishWorks.update({ data }).pipe(
            map(() => {
              const counter = this.selectedWorks.size;
              this.publishMessageForMobiles(counter);
              this.unSelectAllWorks();
              this.worksCollection.search();
              this._toastService.show({
                type: ToastStatusEnum.Success,
                message:
                  this.selectedUnpublishedAssignedWorksSize > 1
                    ? marker('Works was successfully published')
                    : marker('Work was successfully published')
              });
            }),
            catchError(({ error }) => handleError(error, this._toastService)),
            map(() => modal.close())
          );
        })
      )
      .subscribe();
  }

  getJSON(item) {
    return JSON.parse(item);
  }

  openTooltip(elem: WorkDetailTooltipComponent | AbsenceDetailTooltipComponent | SortTooltipComponent) {
    elem.menuTrigger.openMenu();
    if (elem instanceof WorkDetailTooltipComponent || elem instanceof AbsenceDetailTooltipComponent) {
      elem.open();
    }
  }

  onLongPressAction(e, work, date, workerId) {
    this.isSelectMode = true;
    this.$isSelectMode.next(true);

    this.handleSelectWork(e, work, date, workerId);

    this.worksSelected.next(true);
  }

  onDeleteWorkHandler(data): void {
    this.worksCollection.search();
  }

  onEditWorkHandler(work, e): void {
    const messageObj = {
      title: 'editWork',
      body: {
        work_id: work.id
      }
    };

    if (this._platform.IOS) {
      // @ts-ignore
      window?.webkit?.messageHandlers?.jsEditWorkHandler.postMessage(messageObj);
    } else if (this._platform.ANDROID) {
      const stringVal = JSON.stringify(messageObj);
      // @ts-ignore
      window?.AndroidJsBridge?.editWork(stringVal);
    }
  }

  reloadWorks(): void {
    this.worksCollection.search();
  }

  reloadData() {
    // @ts-ignore
    window.reloadData = () => {
      window.document.getElementById('reloadWorksButton').click();
    };
  }

  createWork({ userId, date }): void {
    const messageObj = {
      title: 'createWork',
      body: {
        user_id: userId,
        date: date
      }
    };

    if (this._platform.IOS) {
      // @ts-ignore
      window?.webkit?.messageHandlers?.jsCreateWorkHandler.postMessage(messageObj);
    } else if (this._platform.ANDROID) {
      const stringVal = JSON.stringify(messageObj);
      // @ts-ignore
      window?.AndroidJsBridge?.createWork(stringVal);
    }
  }

  private selectWorkBy(set, id, skipExist = false): void {
    if (set.has(id) && !skipExist) {
      set.delete(id);
    } else {
      set.add(id);
    }
  }

  handleSelectWork(e = null, work, date, workerId, skipExist = false): void {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    const workId = work.id;
    this.selectWorkBy(this.selectedWorksByDate[date].set, workId, skipExist);
    this.selectWorkBy(this.selectedWorksByWorker[workerId].set, workId, skipExist);
    this.selectWorkBy(this.selectedWorks, workId, skipExist);

    if (!work?.is_published && work.user_id) {
      this.selectWorkBy(this.selectedUnpublishedAssignedWorks, workId, skipExist);
    }

    const sumWorksPerDate = this.sumWorksPerDate(date);
    const sumWorksPerWorkers = this.sumWorksPerWorkers(workerId);

    this.selectedWorksByDate[date].allSelected = this.selectedWorksByDate[date].set.size === sumWorksPerDate;
    this.selectedWorksByWorker[workerId].allSelected = this.selectedWorksByWorker[workerId].set.size === sumWorksPerWorkers;

    if (e) {
      this.worksSelected.next(true);
    }
    this.reloadActionItems();
  }

  private sumWorksPerDate(date): number {
    return this.workersCollection.items.reduce((prev, current) => {
      // @ts-ignore
      const next = this.timeSheetData[current.id]['works'][date].length;
      return prev + next;
    }, 0);
  }

  private sumWorksPerWorkers(workerId): number {
    return this.groupsTimesheetDays.reduce((prev, current) => {
      const date = current[1][0].date;
      // @ts-ignore
      const next = this.timeSheetData[workerId]['works'][date].length;
      return prev + next;
    }, 0);
  }

  private calculateAllWorks() {
    const list = [];

    if (!Object.keys(this.timeSheetData).length) {
      return list;
    }

    this.groupsTimesheetDays.forEach((day) => {
      const date = day[1][0].date;
      this.workersCollection.items.forEach((worker) => {
        // @ts-ignore
        const w = this.timeSheetData[worker.id]['works'][date];
        // list.push(w);
        Array.prototype.push.apply(list, w);
      });
    });

    return list;
  }

  selectAllWorks(skip = true): void {
    this.groupsTimesheetDays.forEach((day) => {
      const date = day[1][0].date;
      this.handleSelectWorkByDate(null, date, true);
      this.workersCollection.items.forEach((worker) => {
        // @ts-ignore
        this.handleSelectWorkByWorker(null, worker.id, true);
      });
    });
  }

  unSelectAllWorks(): void {
    this.selectedWorks = new Set();
    this.selectedUnpublishedAssignedWorks = new Set();
    this.groupsTimesheetDays.forEach((day) => {
      const date = day[1][0].date;
      this.selectedWorksByDate[date].set = new Set();
      this.selectedWorksByDate[date].allSelected = false;
      this.workersCollection.items.forEach((worker) => {
        // @ts-ignore
        const workerId = worker.id;
        this.selectedWorksByWorker[workerId].set = new Set();
        this.selectedWorksByWorker[workerId].allSelected = false;
      });
    });

    this.selectedWorkCollection.unSelectAll();
    this.isSelectMode = false;
    this.$isSelectMode.next(false);
    this.reloadActionItems();
  }

  handleSelectWorkByDate(e, date, skipUnselect = false): void {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    const groupsTimesheetDays = this.sumWorksPerDate(date);

    if (this.selectedWorksByDate[date].set.size === groupsTimesheetDays && !skipUnselect) {
      this.handleUnSelectWorkByDate(date);
    } else {
      this.selectedWorksByDate[date].allSelected = true;
      this.workersCollection.items.forEach((item) => {
        // @ts-ignore
        const workers = this.timeSheetData[item.id]['works'][date];
        workers.forEach((work) => {
          // @ts-ignore
          this.handleSelectWork(null, work, date, item.id, true);
        });
      });
    }

    this.worksSelected.next(true);
  }

  handleSelectWorkByWorker(e, workerId, skipUnselect = false): void {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    const sizeOfWorks = this.sumWorksPerWorkers(workerId);

    if (this.selectedWorksByWorker[workerId].set.size === sizeOfWorks && !skipUnselect) {
      this.handleUnSelectWorkByWorker(workerId);
    } else {
      this.selectedWorksByWorker[workerId].allSelected = true;
      this.groupsTimesheetDays.forEach((item) => {
        const date = item[1][0].date;
        // @ts-ignore
        const workers = this.timeSheetData[workerId]['works'][date];
        workers.forEach((work) => {
          // @ts-ignore
          this.handleSelectWork(null, work, date, workerId, true);
        });
      });
    }

    this.worksSelected.next(true);
  }

  private handleUnSelectWorkByDate(date): void {
    this.selectedWorksByDate[date].set = new Set();
    this.selectedWorksByDate[date].allSelected = false;
    this.workersCollection.items.forEach((item) => {
      // @ts-ignore
      const workers = this.timeSheetData[item.id]['works'][date];
      workers.forEach((work) => {
        // @ts-ignore
        this.selectWorkBy(this.selectedWorksByWorker[item.id].set, work.id);
        this.selectWorkBy(this.selectedWorks, work.id);
        if (this.selectedUnpublishedAssignedWorks.has(work.id)) {
          this.selectWorkBy(this.selectedUnpublishedAssignedWorks, work.id);
        }
        // @ts-ignore
        this.selectedWorksByWorker[item.id].allSelected = false;
      });
    });
    this.reloadActionItems();
    // this.worksSelected.next(true);
  }

  private handleUnSelectWorkByWorker(workerId): void {
    this.selectedWorksByWorker[workerId].set = new Set();
    this.selectedWorksByWorker[workerId].allSelected = false;

    this.groupsTimesheetDays.forEach((item) => {
      const date = item[1][0].date;
      // @ts-ignore
      const workers = this.timeSheetData[workerId]['works'][date];
      this.selectedWorksByDate[date].allSelected = false;
      workers.forEach((work) => {
        this.selectWorkBy(this.selectedWorksByDate[date].set, work.id);
        this.selectWorkBy(this.selectedWorks, work.id);
        if (this.selectedUnpublishedAssignedWorks.has(work.id)) {
          this.selectWorkBy(this.selectedUnpublishedAssignedWorks, work.id);
        }
        this.selectedWorksByDate[date].allSelected = false;
      });
      this.reloadActionItems();
      // this.worksSelected.next(true);
    });
  }

  initTimeSheetDays(): void {
    const periodDates = this._timeService.getPeriodDates(this.filters.get('fromFull'), this.filters.get('toFull'));
    this.timesheetDays = periodDates.days;

    const groupsTimesheetDays = this.timesheetDays.reduce((acc, d) => {
      const keyObj = JSON.stringify({ month: d.month, year: d.year, week: d.week, date: d.date });

      this.weekNumber = d.week;

      this.selectedWorksByDate[d.date] = {
        set: new Set(),
        allSelected: false
      };

      if (acc[keyObj]) {
        acc[keyObj].push(d);
      } else {
        acc[keyObj] = [d];
      }
      return acc;
    }, []);

    this.groupsTimesheetDays = Object.entries(groupsTimesheetDays).sort(([keyA, valueA], [keyB, valueB]) => {
      const dateFrom: any = new Date(valueA[0].date);
      const dateTo: any = new Date(valueB[0].date);
      return dateFrom - dateTo;
    });
  }

  identifyWorker(index, item) {
    return item.id;
  }

  identifyDay(index, item) {
    return item.name;
  }

  private calculateProgressCellWidth() {
    const numberDays = this.timesheetDays.length;
    const wrapperWidth = this.scrollWrapper.nativeElement.offsetWidth - numberDays;
    const cellWidth = wrapperWidth / numberDays;

    if (cellWidth > 50) {
      this.progressCellWidth = cellWidth;
    } else {
      this.progressCellWidth = 50;
    }
  }

  private generateDefaultDragArrays(): void {
    this.workersCollection.items.forEach((worker) => {
      // @ts-ignore
      this.selectedWorksByWorker[worker.id] = {
        set: new Set(),
        allSelected: false
      };

      const periodDates = this.timesheetDays;
      // @ts-ignore
      if (!this.timeSheetData[worker.id]) {
        // @ts-ignore
        this.timeSheetData[worker.id] = {
          dates: {},
          works: {}
        };
      } else {
        // @ts-ignore
        this.timeSheetData[worker.id]['works'] = {};
      }

      periodDates.forEach((periodDate) => {
        // @ts-ignore
        this.timeSheetData[worker.id].works[periodDate.date] = [];
        // @ts-ignore
        this.cdkWorkersList.push(worker.id + '-' + periodDate.date);
      });
    });
  }

  private initUnscheduledSection(data): void {
    // @ts-ignore
    this.showUnscheduledSection = data == [] ? false : !!data[''];

    this.workersCollection.items[0]['show'] = this.showUnscheduledSection;
    this._cdRef.detectChanges();
  }

  private initTimeSheetData(data: any): Observable<boolean> {
    this.initUnscheduledSection(data);
    this.calculateProgressCellWidth();

    if (data.length !== 0) {
      this.timeSheetData = data;
      const copiedResponse = JSON.parse(JSON.stringify(data));
      this.generateDefaultDragArrays();

      for (const key in copiedResponse) {
        if (this.timeSheetData[key]) {
          Object.entries(copiedResponse[key].works).forEach((item) => {
            if (this.timeSheetData[key].works[item[0]]) {
              this.timeSheetData[key].works[item[0]] = item[1];
            }
          });
        }
      }
    } else {
      this.generateDefaultDragArrays();
    }

    this.selectedWorkCollection.setProperty('items', this.calculateAllWorks());
    this.selectedWorkCollection.totalSelectedItems = this.selectedWorkCollection.items.length;

    this.worksCollection.items = data;
    this.worksCollection.detectChanges();

    return of(true);
  }

  private initFilterSubscription() {
    this.filters.changed
      .pipe(
        takeUntil(this._destroyed$),
        tap((params) => {
          if (params?.fromFull && params?.toFull) {
            this.initHolidays();
          }
        }),
        filter((params) => this.filters.get(this.filterTypesEnum.View) === MobileSchedulerTypesEnum.FullSchedule)
      )
      .subscribe(() => {
        this.initTimeSheetDays();
        this.unSelectAllWorks();

        if (!this._auth.isWorker()) {
          const workersIds = this.filters.get(this.filterTypesEnum.Workers);

          if (workersIds && workersIds.length) {
            this.workersCollection.setParams('ids', workersIds.join(' ,'));
          } else {
            this.workersCollection.clearParams('ids');
          }
        }

        this.workersCollection.search();
      });
  }

  private initWorkerSubscription() {
    this.workersCollection.onLoad$.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      if (!this._auth.isWorker()) {
        this.workersCollection.unshiftItem({
          id: '', // TODO: add enum, use the same as response from BE
          full_name: marker('Unassigned'),
          show: this.showUnscheduledSection
        });
      }

      this.worksCollection
        .setParams('from_time', this.filters.get('fromFull'))
        .setParams('to_time', this.filters.get('toFull'))
        .search();
    });
  }

  onUserSumLoaded(data = {}) {
    this.workersCollection.items.forEach((item: any) => {
      item.hours_sum = data[item.id]?.hours_sum || 0;
      item.works_sum = data[item.id]?.works_sum || 0;
    });
  }

  private initTimesheetCollection() {
    this.worksCollection.onLoad$
      .pipe(
        switchMap((data) => this.initTimeSheetData(data)),
        takeUntil(this._destroyed$)
      )
      .subscribe();
  }

  private reloadActionItems() {
    const disabled = this.selectedUnpublishedAssignedWorksSize === 0;
    const actions = this.actions.map((item, index) => (index === 0 ? { ...item } : { ...item, disabled }));
    this.actions = [...actions];
  }

  initHolidays(): void {
    const from = this.filters.get('fromFull');
    const to = this.filters.get('toFull');

    this._holidaysService
      .getHolidays({ from, to })
      .pipe(
        take(1),
        map((list) => (this.holidaysObj = list)),
        map(() => this._cdRef.markForCheck())
      )
      .subscribe();
  }

  ngOnInit() {
    this.initWorkerSubscription();
    this.initTimesheetCollection();
    this.initFilterSubscription();

    this.worksSelected.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      this.selectedWorkCollection.selectedItems$ = new BehaviorSubject(this.selectedWorks).asObservable();
      this.selectedWorkCollection.setProperty('selectedItems', this.selectedWorks).selectItem({ id: null, user_id: null });

      this.selectedWorkCollection.selectItem({ id: null, user_id: null });

      if (this.selectedWorks.size === 0) {
        this.isSelectMode = false;
        this.$isSelectMode.next(false);
      }
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next(true);
    this.worksCollection.clear();
    this.workersCollection.clear();
  }

  dragStarted(event): void {
    this.isStartDrag = true;
  }

  dragEnd(event): void {
    this.isStartDrag = false;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      const toWorkerId = event.container.element.nativeElement['data-worker-id'];
      const toDate = event.container.element.nativeElement['data-date'];

      const currentWork = event.previousContainer.data[event.previousIndex];

      const workItem = new Item({
        api: this._crud.createEntity({ name: 'work' })
      });

      workItem.setProperty('data', currentWork);

      workItem
        .updateItem({
          data: {
            from_date: toDate,
            from_time: workItem.data.from_time && workItem.data.from_time.slice(0, 5),
            duration: workItem.data.duration,
            user_id: toWorkerId || null
          }
        })
        .subscribe(
          () => {
            this.worksCollection.search();
          },
          (err) => {
            const errorResponse = err.error;
            handleError(errorResponse, this._toastService);
          }
        );

      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  constructor(
    private _actionsService: ActionsService,
    private _absenceActionsService: AbsenceActionsService,
    private _crud: CrudService,
    private _timeService: TimeService,
    private _api: ApiService,
    private _filtersStore: FiltersStoreService,
    private _cdRef: ChangeDetectorRef,
    private _auth: AuthService,
    private _entityService: EntityEditService,
    private _schedulerService: SchedulerService,
    public _platform: Platform,
    private _holidaysService: HolidaysService,
    private _translate: TranslateService,
    private _toastService: ToasterService
  ) {
    this.filters = this._filtersStore.filters.mobileScheduler;
    this.worksCollection = this._schedulerService.schedulerPlannerCollection;

    this.reloadData();

    this.workersCollection = new Collection({
      api: this._api.workers,
      params: {
        sort: 'name',
        status: 'active',
        expand: 'full_name,avatars,wages',
        per_page: 999
      }
    });

    this.workersCollection.mapItems = (item) => {
      item['show'] = true;
      return item;
    };

    this.selectedWorkCollection = new Collection();
    this.selectedWorkCollection.setProperty('selectedItems', this.selectedWorks);
    this.selectedWorkCollection.selectedItems$ = new BehaviorSubject(this.selectedWorks).asObservable();

    this.deleteManyItems = this._crud.createDeleteManyEntity({ name: 'work/batch-delete' });
  }
}
