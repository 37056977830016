import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, of, Subject } from 'rxjs';
import { delay, mergeMap, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[longPress]'
})
export class LongPressDirective implements OnInit, OnDestroy {
  @Input() canSelect = true;
  @Output() longPress = new EventEmitter();

  private _destroyed$ = new Subject();

  ngOnInit() {
    const mousedown$ = fromEvent<MouseEvent>(this.elementRef.nativeElement, 'touchstart');
    const mouseup$ = fromEvent<MouseEvent>(this.elementRef.nativeElement, 'touchend');

    const longClick$ = mousedown$.pipe(mergeMap((event) => of(event).pipe(delay(700), takeUntil(mouseup$))));

    longClick$.pipe(takeUntil(this._destroyed$)).subscribe((event) => {
      if (this.canSelect) {
        this.longPress.emit();
      }
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next(true);
  }

  constructor(private elementRef: ElementRef) {}
}
