<section class="wrapper mobile-full-schedule">
  <loader class="workers-loader" [loading]="workersCollection.loading$"></loader>

  <button style="visibility: hidden; height: 0" id="reloadWorksButton" (click)="reloadWorks()">Press</button>

  <div class="dates-container">
    <div class="row-flex mx-0" *ngFor="let item of groupsTimesheetDays">
      <div class="row-flex mx-0 row-flex--nowrap">
        <div class="dates" [style.min-width.px]="progressCellWidth" *ngFor="let date of item[1]; trackBy: identifyDay">
          <span class="date-cell-checkbox" *ngIf="selectedWorksByDate[date.date]?.set?.size">
            <div class="checkbox">
              <label class="checkbox__label" (click)="handleSelectWorkByDate($event, date.date)">
                <input type="checkbox" [checked]="selectedWorksByDate[date.date]?.allSelected" />
                <span class="checkbox__check"></span>
              </label>
            </div>
          </span>

          <div [class.text-orange]="date.date === today" class="dates__number">{{ date.number }}</div>
          <div [class.text-orange]="date.date === today" class="dates__name">{{ date.name | translate }}</div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="
      workersCollection.loaded &&
      !workersCollection.loading &&
      worksCollection.loaded &&
      filters.get(filterTypesEnum.View) === MobileSchedulerTypesEnum.FullSchedule
    "
    class="text-ellipsis group-header w-100">
    <app-sum-hours
      [fromFilterKey]="'fromFull'"
      [toFilterKey]="'toFull'"
      [isWorkerView]="true"
      [showWeekLabel]="true"
      [filters]="filters"
      [timesheetDays]="timesheetDays"
      (onUserSumLoaded)="onUserSumLoaded($event)"></app-sum-hours>
  </div>

  <div class="planner-container">
    <div class="planner-wrap" #plannerScroll>
      <div class="days-list">
        <div class="accordion-items-wrapper">
          <ng-container *ngFor="let item of workersCollection.items$ | async; trackBy: identifyWorker">
            <mat-accordion *ngIf="item.show">
              <mat-expansion-panel class="expansion-panel">
                <mat-expansion-panel-header class="expansion-header">
                  <mat-panel-title>
                    <div class="user-item-wrapper w-100">
                      <div class="user-item">
                        <div class="pr-1" *ngIf="item.id !== ''">
                          <logo [url]="item?.avatars?.small_thumb_path"></logo>
                        </div>

                        <div>
                          <div class="user-item__title" [class.text-heading]="item.id === ''">
                            <ng-container *ngIf="item.id === ''; else userFullName">
                              <span>
                                {{ 'Unassigned' | translate }}
                              </span>
                            </ng-container>

                            <ng-template #userFullName>
                              {{ item.full_name }}
                            </ng-template>

                            <span *ngIf="item.id !== ''" class="text-light">({{ item?.display_id }})</span>
                          </div>

                          <div *ngIf="item.id !== ''" class="text-light full-schedule-user-hours-sum">
                            {{ item?.hours_sum || 0 | secondsToPeriod }} / {{ item?.works_sum || 0 | secondsToPeriod }}
                          </div>

                          <span class="date-cell-checkbox" *ngIf="selectedWorksByWorker[item.id]?.set?.size">
                            <div class="checkbox">
                              <label class="checkbox__label" (click)="handleSelectWorkByWorker($event, item.id)">
                                <input type="checkbox" [checked]="selectedWorksByWorker[item.id]?.allSelected" />
                                <span class="checkbox__check"></span>
                              </label>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <ng-template matExpansionPanelContent>
                  <div class="timesheet-row">
                    <ng-container *ngFor="let date of timesheetDays">
                      <div
                        class="row__item"
                        [class.red-bg]="holidaysObj && holidaysObj[date?.date]"
                        [class.weekend]="date?.dayNumber === 6 || date?.dayNumber === 0">
                        <div class="w-100">
                          <ng-container *ngIf="worksCollection.loading$ | async">
                            <div class="works-loader">
                              <loader [loading]="worksCollection.loading$" [small]="true"></loader>
                            </div>
                          </ng-container>

                          <a class="timesheet-clickable-cell">
                            <div
                              cdkDropList
                              id="{{ item?.id }}-{{ date?.date }}"
                              [data-worker-id]="item?.id"
                              [data-date]="date?.date"
                              [cdkDropListConnectedTo]="cdkWorkersList"
                              [cdkDropListData]="timeSheetData[item.id] && timeSheetData[item.id]?.works[date?.date]"
                              class="example-list workers-drag-wrapper"
                              [class.pb-4]="timeSheetData[item.id]?.dates && timeSheetData[item.id]?.dates[date?.date]"
                              (cdkDropListDropped)="drop($event)">
                              <ng-container
                                *ngIf="
                                  timeSheetData[item.id] &&
                                  timeSheetData[item.id]?.works &&
                                  timeSheetData[item.id]?.works[date.date]
                                ">
                                <ng-container *ngFor="let work of timeSheetData[item.id]?.works[date.date]; let last = last">
                                  <div
                                    cdkDrag
                                    [cdkDragDisabled]="$isSelectMode | async"
                                    (cdkDragStarted)="dragStarted($event)"
                                    (cdkDragDropped)="dragEnd($event)"
                                    longPress
                                    [canSelect]="!isStartDrag"
                                    (longPress)="onLongPressAction($event, work, date.date, item.id)"
                                    class="card"
                                    [ngClass]="{ 'select-mode': isSelectMode }"
                                    [class.unassigned]="item.id === ''"
                                    [class.published]="work?.is_published"
                                    (click)="onEditWorkHandler(work, $event)">
                                    <div
                                      class="checkbox"
                                      [class.show-checkbox]="
                                        selectedWorksByDate[date.date]?.set?.size || selectedWorksByWorker[item.id]?.set?.size
                                      ">
                                      <label class="checkbox__label" (click)="handleSelectWork($event, work, date.date, item.id)">
                                        <input type="checkbox" [checked]="selectedWorks.has(work.id)" />
                                        <span class="checkbox__check"></span>
                                      </label>
                                    </div>

                                    <div class="text-ellipsis text-bold mh-15">
                                      <i class="icon icon-Calendar"></i>
                                      {{ work?.project?.name }}
                                      <span *ngIf="work?.project" class="text-light">({{ work?.project?.display_id }})</span>
                                    </div>
                                    <div class="text-ellipsis">{{ work?.client?.name }}</div>
                                    <div class="text-ellipsis">{{ work?.duration | duration: work?.from_time }}</div>
                                  </div>

                                  <div
                                    *cdkDragPlaceholder
                                    class="card example-custom-placeholder"
                                    [class.mb-4]="last && timeSheetData[item.id] && timeSheetData[item.id]?.dates[date.date]">
                                    <div class="text-ellipsis text-bold mh-15">
                                      <i class="icon icon-Calendar"></i>
                                      {{ work?.project?.name }}
                                      <span *ngIf="work?.project" class="text-light">({{ work?.project?.display_id }})</span>
                                    </div>
                                    <div class="text-ellipsis">{{ work?.client?.name }}</div>
                                    <div class="text-ellipsis">{{ work?.duration | duration: work?.from_time }}</div>
                                  </div>

                                  <ng-container *ngIf="last">
                                    <div
                                      (click)="createWork({ userId: item.id, date: date.date })"
                                      class="card empty-card last-empty-card">
                                      <i class="icon icon-Plus_light_ico"></i>
                                    </div>
                                  </ng-container>
                                </ng-container>
                              </ng-container>

                              <ng-container *ngIf="worksCollection.loaded && !timeSheetData[item.id]?.works[date.date]?.length">
                                <div (click)="createWork({ userId: item.id, date: date.date })" class="card empty-card">
                                  <i class="icon icon-Plus_light_ico"></i>
                                </div>
                              </ng-container>
                            </div>
                          </a>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-template>
              </mat-expansion-panel>
            </mat-accordion>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <ng-container *rxLet="selectedWorkCollection.selectedItems$; let selectedItems">
    <actions-tile
      *ngIf="selectedItems.size"
      [collection]="selectedWorkCollection"
      (onUnSelectAll)="unSelectAllWorks()"
      [actions]="actions"
      [checkboxLabel]="'Select All' | translate"
      (onSelectAll)="selectAllWorks()"
      (canceledChanged)="unSelectAllWorks()">
    </actions-tile>
  </ng-container>
</section>
