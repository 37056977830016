<section class="mobile-scheduler__wrapper" [class.dark-mode]="filters.get(filterTypesEnum.DarkMode)">
  <button style="visibility: hidden; height: 0" id="loginButton" (click)="loginMethod()">Press</button>

  <ng-container *ngIf="isAuthorized | async; else noAuthorized">
    <div class="mobile-scheduler__header">
      <div class="back-btn">
        <ng-container *ngIf="filters.get(filterTypesEnum.Back) === MobileSchedulerTypesEnum.Show">
          <button (click)="backToNative()">
            <i class="icon icon-Arrow_ico"></i>
          </button>
        </ng-container>
      </div>

      <span class="header-title">{{ 'Scheduler' | translate }}</span>
    </div>

    <ng-container *ngIf="!isWorker">
      <div class="scheduler-tabs">
        <tabs-tile [tabs]="tabs" [hasRouting]="false" (selectedTab)="changeActiveView($event)"> </tabs-tile>
      </div>
    </ng-container>

    <div class="period-picker-wrapper">
      <ng-container *ngIf="filters.get(filterTypesEnum.View) === MobileSchedulerTypesEnum.MySchedule">
        <scheduler-period-picker
          class="mobile-period-picker"
          [includeOption]="[periodTypes.Day, periodTypes.Week]"
          [fromDate]="filters.get(filterTypesEnum.FromDate)"
          [toDate]="filters.get(filterTypesEnum.ToDate)"
          [period]="filters.get(filterTypesEnum.Period)"
          [filters]="filters"
          (updatedFilters)="updateSelectedItems($event)"
          [isMobile]="true">
        </scheduler-period-picker>
      </ng-container>

      <ng-container *ngIf="filters.get(filterTypesEnum.View) === MobileSchedulerTypesEnum.FullSchedule">
        <scheduler-period-picker
          class="mobile-period-picker"
          [showPeriod]="false"
          [fromDate]="filters.get(filterTypesEnum.FullCalendarFromDate)"
          [toDate]="filters.get(filterTypesEnum.FullCalendarToDate)"
          [period]="'week'"
          [filters]="filters"
          (updatedFilters)="updateFullCalendarSelectedItems($event)"
          [isMobile]="true">
        </scheduler-period-picker>
      </ng-container>
    </div>
    <div *ngIf="isWorker || filters.get(filterTypesEnum.View) === MobileSchedulerTypesEnum.MySchedule" class="sum-hours-wrapper">
      <app-sum-hours
        [isWorkerView]="true"
        [onlyMyHours]="true"
        [showWeekLabel]="filters.get(filterTypesEnum.Period) === periodTypes.Week"
        [filters]="filters"
        [timesheetDays]="timesheetDays"></app-sum-hours>
    </div>

    <mobile-my-scheduler
      [hidden]="!(filters.get(filterTypesEnum.View) === MobileSchedulerTypesEnum.MySchedule)"
      [filters]="filters"
      [timesheetDays]="timesheetDays">
    </mobile-my-scheduler>

    <ng-container *ngIf="!isWorker">
      <div
        class="mobile-scheduler__container"
        [hidden]="!(filters.get(filterTypesEnum.View) === MobileSchedulerTypesEnum.FullSchedule)">
        <mobile-full-schedule></mobile-full-schedule>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #noAuthorized>
    <div class="no-authorized-wrapper">
      <div class="svenn-loader">
        <img src="assets/sidebar/Svenn_App_icon.png" alt="Svenn" class="svenn-loader--logo" />
        <img src="assets/iconmoon-icons/Svenn_Logo_Dark_ico.svg" alt="Svenn" class="svenn-loader--text" />
      </div>
    </div>
  </ng-template>
</section>
