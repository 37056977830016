import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MobileSchedulerComponent } from '@features/mobile-scheduler/mobile-scheduler.component';

const routes: Routes = [
  {
    path: 'calendar',
    component: MobileSchedulerComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MobileSchedulerRoutingModule {}
