<loader style="display: none" [loading]="worksCollection.loading$" [small]="true"></loader>
<ng-container *ngIf="!isDayView">
  <div
    *ngFor="let day of timesheetDays; trackBy: identifyDay"
    class="row-flex mx-0 day-item"
    [class.inactive]="day.date | isPast"
    [class.red-bg]="holidaysObj && holidaysObj[day?.date]">
    <div class="col-1" [class.text-orange]="day.date === today">
      <div class="day-number-label">{{ day.number }}</div>
      <div class="day-name-label">{{ day.name | translate | uppercase }}</div>
    </div>
    <div class="col-11 p-0">
      <ng-container *ngIf="worksCollection.loading$ | async">
        <div class="works-loader">
          <loader [loading]="worksCollection.loading$" [small]="true"></loader>
        </div>
      </ng-container>

      <ng-container *ngIf="timeSheetData[user_id] && timeSheetData[user_id]?.works && timeSheetData[user_id]?.works[day.date]">
        <div *ngFor="let work of timeSheetData[user_id]?.works[day.date]; let last = last">
          <div class="card" [class.published]="work?.is_published" [class.mb-1]="!last" (click)="onShowDetailsHandler(work)">
            <ng-container *ngTemplateOutlet="workCard; context: { work }"></ng-container>
          </div>
        </div>
      </ng-container>

      <ng-container
        *ngIf="
          !worksCollection.loading &&
          worksCollection.loaded &&
          !(timeSheetData[user_id] && timeSheetData[user_id]?.works && timeSheetData[user_id]?.works[day.date]?.length)
        ">
        <ng-container *ngTemplateOutlet="noWorkScheduled; context: { class: 'no-scheduled-label' }"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>

<div *ngIf="isDayView" class="w-100 h-100 position-relative" [class.red-bg]="holidaysObj && holidaysObj[timesheetDay?.date]">
  <ng-container *ngIf="worksCollection.loading$ | async">
    <div class="works-loader">
      <loader [loading]="worksCollection.loading$" [small]="true"></loader>
    </div>
  </ng-container>

  <ng-container
    *ngIf="
      !worksCollection.loading &&
      worksCollection.loaded &&
      !(timeSheetData[user_id] && timeSheetData[user_id]?.works && timeSheetData[user_id]?.works[timesheetDay?.date])
    ">
    <ng-container *ngTemplateOutlet="noWorkScheduled; context: { class: 'no-scheduled-label-large' }"></ng-container>
  </ng-container>

  <div
    *ngIf="timeSheetData[user_id] && timeSheetData[user_id]?.works && timeSheetData[user_id]?.works[timesheetDay?.date]"
    class="day-view-wrapper">
    <div class="position-relative" [style.min-width.px]="minWidthRow">
      <div *ngFor="let hour of dayHours; let first = first" class="row-flex mx-0 hour-row">
        <div class="hour-title">
          <div *ngIf="!first" class="hour-label" [class.red-bg]="holidaysObj && holidaysObj[timesheetDay?.date]">
            {{ hour }}
          </div>
        </div>
      </div>

      <ng-container
        *ngIf="timeSheetData[user_id] && timeSheetData[user_id]?.works && timeSheetData[user_id]?.works[timesheetDay.date]">
        <div
          *ngFor="let work of timeSheetData[user_id]?.works[timesheetDay.date]; let i = index; let first = first"
          class="work card"
          [style.top.px]="work?.top"
          [style.left]="work?.left"
          [style.right.px]="0"
          [style.height.px]="work?.height"
          (click)="onShowDetailsHandler(work)">
          <span *ngIf="first" #firstWork></span>
          <ng-container *ngTemplateOutlet="workCard; context: { work }"></ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template let-work="work" #workCard>
  <div class="text-ellipsis text-bold mh-15">
    <i class="icon icon-Calendar"></i>
    {{ work?.project?.name }}
    <span *ngIf="work?.project" class="text-light">({{ work?.project?.display_id }})</span>
  </div>
  <div class="text-ellipsis ml-2">{{ work?.client?.name }}</div>
  <div class="text-ellipsis ml-2">{{ work?.duration | duration: work?.from_time }}</div>
</ng-template>

<ng-template #noWorkScheduled let-class="class">
  <div [class]="class">
    {{ 'No work Scheduled' | translate }}
  </div>
</ng-template>
