import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateUiModule } from '@core/translation/translate-ui.module';
import { WorkDetailTooltipModule } from '@features/scheduler/work-scheduler/work-detail-tooltip/work-detail-tooltip.module';
import { LoaderModule } from '@ui-kit/loader/loader.module';
import { CommonPipesModule } from '@ui-kit/pipes/common-pipes.module';
import { MobileMySchedulerComponent } from './mobile-my-scheduler.component';

@NgModule({
  declarations: [MobileMySchedulerComponent],
  exports: [MobileMySchedulerComponent],
  imports: [CommonModule, WorkDetailTooltipModule, TranslateUiModule, CommonPipesModule, LoaderModule]
})
export class MobileMySchedulerModule {}
